<template>
  <div class="channel-div center">
    <div class="m-10">
      <p :class="`color-white ${isMobile ? 'font-18 my-30' : 'font-25 m-40'}`">{{ $t('desc_for_channel') }}</p>
      <p :class="`my-20 color-white ${isMobile ? 'font-15' : 'font-20'}`" v-if="message">{{ message }}</p>
      <div :class="`mb-20 p-5 box-white ${isMobile ? 'w-100' : 'w-50 mx-auto'} d-flex flex-column`" style="text-align: left;" v-else-if="!myInfo">
        <div class="my-5">
          <span class="ml-2 font-label">{{ $t('email') }}</span>
          <input class="mt-1 form-control" v-model="email" onpaste="return false;" oninput="this.value = this.value.replace(/[ ]/g,'');" />
          <br/>
          <span class="ml-2 font-label">{{ $t('repeat_email') }}</span>
          <input class="mt-1 form-control" v-model="repeat_email" onpaste="return false;" oninput="this.value = this.value.replace(/[ ]/g,'');" />
          <br/>
          <span class="my-5 font-label color-blue">{{ $t('desc_enter_email_exactly_required_for_next_login') }}</span>
        </div>
        <div class="my-5 d-flex justify-content-end">
          <v-btn class="button-normal" @click="getUserOrRegisterGuestTask()">
            <span class="px-10">{{ $t('join_now') }}</span>
          </v-btn>
        </div>
      </div>
      <div class="d-inline mr-10" v-if="platform === 'Desktop' || platform === 'iOS'">
        <a :href="APP_STORE_URL" target="_blank">
          <img class="store-image" :src="assets.app_store" :height="isMobile ? 40 : 60" />
        </a>
      </div>
      <div class="d-inline" v-if="platform === 'Desktop' || platform === 'Android'">
        <a :href="GOOGLE_PLAY_URL" target="_blank">
          <img class="store-image" :src="assets.google_play" :height="isMobile ? 40 : 60" />
        </a>
      </div>
      <p class="m-10">&nbsp;</p>
    </div>
  </div>
</template>

<style scoped>
  .channel-div {
    background-image: url('/media/image/channel_bg.jpg');
    background-size: cover;
  }
</style>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getPlatform, getDiffFromTimestamp, getDateStringFromTimestamp, isValidEmail, showLoading, showFunctionError } from '../../../functions';

import google_play from '@/assets/images/google_play.png';
import app_store from '@/assets/images/app_store.png';

export default {
  name: 'Channel',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    myInfo() {
      return this.$store.state.myInfo;
    }
  },
  data() {
    return {
      assets: {
        google_play,
        app_store
      },
      email: '',
      repeat_email: '',
      message: '',
      platform: getPlatform()
    };
  },
  methods: {
    setMessage(email, userName) {
      if (email && userName) {
        this.message = this.$t('desc_login_app_with_email_or_username', [email, userName]);
      } else if (userName) {
        this.message = this.$t('desc_login_app_with_username', [userName]);
      } else if (email) {
        this.message = this.$t('desc_sent_temporary_password_to_email', [email]);
      }
    },
    joinChannelTask(userId) {
      if (!userId) {
        this.$toast.error(this.$t('alert_unexpected_error'));
        return;
      }
      const params = {
        isJoin: true,
        channelId: this.$route.params.channelId,
        userId: userId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setChannelInfo');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('alert_success'));
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    getUserOrRegisterGuestTask() {
      if (!this.email) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('email')]));
        return;
      }
      if (!isValidEmail(this.email)) {
        this.$toast.error(this.$t('alert_invalid_email'));
        return;
      }
      if (!this.repeat_email) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('repeat_email')]));
        return;
      }
      if (this.email !== this.repeat_email) {
        this.$toast.error(this.$t('alert_emails_not_match'));
        return;
      }
      const params = {
        forGuest: true,
        channelId: this.$route.params.channelId,
        email: this.email
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'registerUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        let userInfo;
        try {
          userInfo = JSON.parse(response.data);
        } catch(exception) {
          userInfo = null;
        }
        if (userInfo && userInfo.userId) {
          if (userInfo.userType === this.USER_NORMAL) {
            this.setMessage(userInfo.email, userInfo.userName);
            this.joinChannelTask(userInfo.userId);
          } else if (userInfo.userType === this.USER_GUEST) {
            if (getDiffFromTimestamp(userInfo.createdAt) > 30 * 60 * 1000) { // If the guest user has been created before 30 mins
              this.message = this.$t('desc_you_already_registered_with_email_temporary_password_sent_at_', [userInfo.email, getDateStringFromTimestamp(userInfo.createdAt)]);
            } else {
              this.setMessage(userInfo.email, '');
            }
            this.joinChannelTask(userInfo.userId);
          } else {
            this.message = this.$t('alert_unexpected_error');
          }
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>